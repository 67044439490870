<template>
  <div class="register">
    <div class="wrapper-register">
      <div class="wrapper-data-register">
        <div class="register-header">ลงทะเบียนแอดมิน</div>
        <v-divider class="mt-1 mb-3"></v-divider>
        <v-form
          v-on:submit.prevent="submitRegister()"
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <div>
            <label class="black--text" for="">ชื่อผู้ใช้</label>
            <v-text-field
              class="mt-1"
              prepend-inner-icon="mdi-account"
              outlined
              dense
              v-model.trim="dataRegister.username"
              :rules="usernameRule"
              required
            ></v-text-field>
          </div>
          <div>
            <label class="black--text" for="">รหัสผ่าน</label>
            <v-text-field
              prepend-inner-icon="mdi-lock"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              @click:append="show1 = !show1"
              outlined
              dense
              class="mt-1"
              v-model.trim="dataRegister.password"
              :rules="passwordRule"
              required
            ></v-text-field>
          </div>

          <div class="text-center mt-4 mb-4">
            <v-btn
              :loading="loadRegister"
              type="submit"
              @click.prevent="submitRegister()"
              class="white--text"
              color="black"
              block
              >ลงทะเบียน</v-btn
            >
          </div>
        </v-form>
        <div class="text-center">
          <v-btn
            @click.prevent="$router.push('/management/login')"
            color="black"
            text
            >เข้าสู่ระบบ</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/Auth.service";
import Swal from "sweetalert2";
export default {
  name: "Register",
  data() {
    return {
      show1: false,
      valid: true,
      loadRegister: false,
      usernameRule: [
        (v) => !!v || "กรุณากรอกชื่อผู้ใช้ของคุณ",
        (v) => /^[a-zA-Z0-9\s]+$/.test(v) || "รูปแบบชื่อผู้ใช้ไม่ถูกต้อง",
      ],
      passwordRule: [
        (v) => !!v || "กรุณากรอกรหัสผ่านของคุณ",
        (v) =>
          /^[a-zA-Z0-9@$!%*?&%#._-]+$/.test(v) || "รูปแบบรหัสผ่านไม่ถูกต้อง",
        (v) => v.length >= 6 || "รหัสผ่านต้องมี 6 ตัวขึ้นไป",
      ],
      loadRegister: false,
      dataRegister: {
        username: "",
        password: "",
      },
    };
  },
  created() {
    if (localStorage.getItem(process.env.VUE_APP_AUTH)) {
      this.$router.push("/management/add-tracking");
    }
  },
  methods: {
    async submitRegister() {
      if (this.$refs.form.validate()) {
        this.loadRegister = true;
        try {
          const response = await AuthService.register(this.dataRegister);
          await Swal.fire({
            icon: "success",
            title: "ลงทะเบียนสำเร็จ",
            showConfirmButton: false,
            timer: 3000,
          });
          this.loadRegister = false;
          this.$router.push("/management/login");
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: error.msg,
            showConfirmButton: false,
            timer: 3000,
          });
          this.loadRegister = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.register {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.register-header {
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 2px;
  color: rgba(34, 34, 34, 0.9);
}
.wrapper-register {
  background: #fff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 600px;
  border-radius: 4px;
  overflow: hidden;
  padding: 2rem 0;
}
.wrapper-image-register {
  background: #2ab473;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}
.wrapper-data-register {
  padding: 0 2rem;
  margin: auto 0;
}

.button_box {
  text-align: center;
}

.button_box .button {
  margin: 30px 20px;
}
@media only screen and (max-width: 700px) {
  .wrapper-register {
    width: 95%;
  }
}
@media only screen and (max-width: 500px) {
  .wrapper-register {
    width: 100%;
    border-radius: 0;
    top: 50%;
    height: 100vh;
  }
}
</style>